<template>
<ons-page class="point-tutorial">
	<!-- ヘッダー -->
	<common-header :classObject="{ 'is-noicon': true }">
		<h1 class="common-header-title__heading"><a @click="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } } )">頭痛ーる&thinsp;forスゴ得</a></h1>
	</common-header>
	<!-- /ヘッダー -->
	<div class="content" id="js_tutorial">
		<div id="js_tutorial_contents" class="point-tutorial__contents sprite is-background">
			<div class="point-tutorial__button" @click="onClick" @touchstart="btnClass = 'is-button_on'" @touchend="btnClass = 'is-button'">
				<i :class="['sprite', btnClass]" />
			</div>
			<div class="point-tutorial__comment">
				<i class="sprite is-comment" />
			</div>
		</div>
	</div>
</ons-page>
</template>

<script>
// Utils
import cmnConst from '@/assets/js/constant.js'

// Compornents
import CommonHeader from '../components/Molecules/CommonHeader'

export default {
	name: 'PointTutorial',
	components: {
		CommonHeader
	},
	data () {
		return {
			btnClass: 'is-button',
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID
		}
	},
	computed: {
	},
	mounted () {
		// リサイズ処理
		const tutorial = document.getElementById('js_tutorial')
		const tutorialContents = document.getElementById('js_tutorial_contents')
		const propWidth = tutorial.offsetWidth
		const zoom = propWidth / cmnConst.INIT_STAGE_WIDTH
		const height = window.innerHeight - (cmnConst.HEADER_HEIGHT + cmnConst.FOOTER_HEIGHT)
		tutorial.setAttribute('style', `height: ${height}px;`)
		tutorialContents.setAttribute('style', `transform: scale(${zoom}, ${zoom}); -webkit-transform: scale(${zoom}, ${zoom});`)

		// コンテンツの高さをemitする。
		// TODO: vons-pageにheight:100vhを指定しているため、フッターマイページボタンが見切れてしまうので一旦window.innerHeightに変更
		// this.$emit('containerHeight', height)
		this.$emit('containerHeight', window.innerHeight - cmnConst.HEADER_HEIGHT)
	},
	beforeDestroy () {
	},
	methods: {
		/**
		 * 地点登録ページへの遷移
		 */
		onClick () {
			this.$router.push({ name: 'Cpsite', query: { url: `${cmnConst.BACKEND_URL}?_path=pointRegistration`, isFirst: true } })
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../assets/sass/variable";

.point-tutorial {
	position: relative;
	height: 100vh;
	max-width: 640px;
	margin: 0 auto;
	overflow: hidden;

	&__contents {
		position: absolute;
		bottom: 0;
		left: 0;
		transform-origin: 0 100%;
	}

	&__button {
		position: absolute;
		top: 50%;
		left: 50%;
		padding-bottom: 44px;
		transform: translate(-50%, -50%);
	}

	&__comment {
		position: absolute;
		bottom: calc((77 / 568) * 100%);
		left: 50%;
		transform: translateX(-50%);
	}

	.sprite {
		background-image: url(~@/assets/img/sprite_point_tutorial.png);
		background-repeat: no-repeat;
		display: block;
	}

	.sprite.is-button {
		width: 458px;
		height: 188px;
		background-position: -5px -5px;
	}

	.sprite.is-button_on {
		width: 458px;
		height: 187px;
		background-position: -5px -203px;
	}

	.sprite.is-background {
		width: 640px;
		height: 1136px;
		background-position: -5px -400px;
	}

	.sprite.is-comment {
		width: 586px;
		height: 280px;
		background-position: -5px -1546px;
	}
}
</style>
